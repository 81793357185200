/* --------------------------------------------------------------
    Import CCS Dependencies
-------------------------------------------------------------- */
@import 'normalize';
@import 'grid';
@import 'icons';
@import 'variables';
@import 'default';
@import 'nav';

/* ----------------------------------------------------------------------------
    ASC Default stuff
----------------------------------------------------------------------------- */
.price {
    display: inline-block;
    margin: 10px;
    padding: 0;
    width: auto;
    height: auto;
    text-align: center;
    font: bold 0 'Trebuchet MS', 'Droid Serif', Arial, sans-serif;
    overflow: visible;
    vertical-align: middle;
    text-shadow: 0 0 40px #333;
    color: #333;
    transition: .1s;
}
@keyframes toggleTextOpacity {
    0%, 50% {color: rgba(255, 255, 255, 0); }
    100% { color: rgba(255, 255, 255, 1); }
}
.price.shown {
    text-shadow: none;
    animation: toggleTextOpacity .1s linear;
}
.price.burst {
    padding: 60px 0 0 0;
    width: 170px;
    height: 170px;
    background: url(../images/price_bg_burst.png) no-repeat 50% 50%;
    text-shadow: 0 0 25px #333;
    color: transparent;
    &.shown{
        color: #333;
        text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    }
}
.price span.dollar_sign, .price span.cents {
	display: inline-block;
	margin: 5px 0 0 0;
	font-size: 30px;
	line-height: 30px;
	vertical-align: top;
}
.price span.dollars {
	display: inline-block;
	margin-left: -1px;
	font-size: 50px;
	line-height: 50px;
	letter-spacing: -1px;
	vertical-align: top;
}
.price span.cents {
	margin: 4px 0 0 3px;
	letter-spacing: -1px;
}
.action {
    float: none;
    margin: 40px auto;
    padding: 0 0 5px 0;
    max-width: 450px;
    height: auto;
    background: #fff no-repeat top right;
    border: 1px solid #37474f;
    border-radius: 4px;
    text-align: center;
}
.action .price.ribbon {
    vertical-align: top;
}
.action .btn {
    margin-top: 14px;
    max-width: 200px;
    vertical-align: middle;
}
/* --------- asc styles --------- */
#header a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 13px;
    border: 0;
    width: 100%;
    max-width: 450px; /* change this to the width of the actual logo */
}
#header a.logo img {
    margin-right: 5px;
    vertical-align: middle;
    width: auto;
    max-width: 150px;
}
#header a.logo span {
	display: block;
    margin-top: 5px;
    font-size: 16px;
    line-height: 1.2;
    vertical-align: middle;
    font-family: 'Open Sans',Verdana;
    font-weight: 300;
    color: #fff;
}
#header a.logo .com {
    display: inline-block;
    margin-top: 0;
    font-size: 16px;
    font-family: 'Open Sans', Verdana;
    vertical-align: baseline;
}
#header a.logo .small {
    display: none;
    margin-top: 0;
    font-size: 18px;
    font-family: 'Open Sans', Verdana;
    font-weight: normal;
}
#header #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
}
#header #support p {
    float: right;
    margin: 2px 12px 0 7px;
}
#header #support p strong {
    font-size: 16px;
}
#header #support a.login {
    float: right;
    margin: 0 0 0 0;
    padding: 0 12px 2px 12px;
    font-weight: normal;
    color: #f8f9fa;
    background: #1e1f1f;
    text-decoration: none;
    border-radius: 0 0 4px 4px;
    border: 0;
}
#header #support a.login:hover {
    background: #6c757d;
}
#header #support a.livechat:hover {
	text-decoration: none;
}
#header #support a.livechat:hover:before {
    color: #6c757d;
}
#header #support span.phone {
    display: none;
    margin-right: 5px;
    padding-right: 8px;
    border-right: 1px dotted #333;
}
#header #support a.livechat {
    color: #f8f9fa;
}
#header a.login:before {
    display: inline-block;
    margin-top: 1px;
    margin-right: 6px;
    width: inherit;
    height: inherit;
    content: "\e828";
    color: inherit;
    font-family: 'icons';
    font-weight: normal;
    text-align: center;
    font-size: 105%;
    vertical-align: top;
}
#header a.livechat:before {
    display: inline-block;
    margin-right: 5px;
    width: inherit;
    height: inherit;
    content: "\e830";
    color: inherit;
    font-family: 'icons';
    font-weight: normal;
    text-align: center;
    font-size: 105%;
    vertical-align: top;
}
#footer {
    padding: 15px 0 10px 0;
    color: #999;
    border-top: 1px dashed #ccc;
    overflow: hidden;
	p {
		margin: 0 0 5px 0; 
		&.trustmark {
			display: none;
			margin: 0;
		}
		&.social {
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}
	a { color: #999; }
	img {
		display: inline-block;
		&.bbb{
			margin: 0 8px;
			border: 0;
		}
	}
	#trustwaveSealImage { margin: 0 8px; }
}
#back_top {
	display: none;
	position: fixed;
	right: 20px;
	bottom: 20px;
	width: 45px;
	height: 45px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background: #ccc;
	cursor: pointer;
	color: #fff;
	filter: alpha(opacity=50);
	opacity: 0.5;
	z-index: 10;
	&:hover {
		filter: alpha(opacity=80);
		opacity: 0.8;
		border: 0;
	}
	&:before {
	  display: inline-block;
	  width: 100%;
	  height: 100%;
	  line-height: 45px;
	  font-family: 'icons';
	  font-style: normal;
	  font-weight: normal;
	  speak: none;
	  text-decoration: inherit;
	  text-align: center;
	  font-variant: normal;
	  text-transform: none;
	  content: '\e825';
	  font-size: 120%;
	}
	
}

a.phone { 
	border: 0; 
	i {
		display: inline-block;
		margin: 0 10px 0 0;
		height: 35px;
		width: 35px;
		font-size: 1.4em;
		color: #ccc;
		line-height: 30px;
		border-radius: 50%;
		border: 2px solid #ccc;
		vertical-align: middle;
	}
} 
a.phone [class^="icon-"]:before, a.phone [class*=" icon-"]:before { line-height: 1.25em; }

/* ----------------------------------------------------------------------------
    Start Page Styles
----------------------------------------------------------------------------- */
html, body { background: #f5f5f5; }
#header_wrap { 
	background: #343a40; 
	border-bottom: 1px solid #ccc;
}

/* CONTENT */
#content_wrap {
    padding: 20px 0 50px;
    background: #fff;
}
h2.home, h3.home {
    margin-top: 0;
}
.card-box {
    word-wrap: break-word;
    background-color: #fff;;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 3px;
	.card-header {
		background-color: #343a40;
		border-bottom: 0;
		padding: 5px 10px;
		h4 { color: #fff; }
	}
	.card-body {
		padding: 20px;
		background: #F5F7FA;
		.status { 
			i {
				color: #fff;
				border-radius: 50px;
				padding: 3px;
				margin: 0 5px 0 2px;
				&.icon-check {
					background: #00d516;
				}
				&.icon-close {
					background: #da4453;
				}
			}
			.verified, .invalid, .expired {
				display: none; 
				padding: 5px 10px;
				border: 1px solid #ccc;
				border-radius: 2px;
			}
			.verified {
				background: #f4fff2;
			}
			.invalid, .expired {
				background: #ffe9eb;
			}
			.text {
				font-size: 1.2em; 
				font-weight: 600; 
			}
		}
		.member_form { 
			display: none;
			form { margin: 0 auto; }
		}
	}
}
.warning {
	display: block;
	padding: 5px 10px;
	border: 1px solid #ccc;
	border-radius: 2px;
	background: #ffe9eb;
	font-size: 1.2em; 
	font-weight: 600; 
	i {
		color: #fff;
		border-radius: 50px;
		padding: 3px;
		margin: 0 5px 0 2px;
		&.icon-close {
			background: #da4453;
		}
	}
}
.alert {
	display: none;
    padding: 10px 20px;
    margin-bottom: 20px;
    border: 1px solid #ffeeba;
    border-radius: 3px;
	color: #856404;
	background-color: #fff3cd;
	p { margin-bottom: 0; }
}
.documents {
	.header {
		padding: .5rem 1rem;
		background-color: #343a40;
		border-top-left-radius: .2rem;
		border-top-right-radius: .2rem;
		h5 { color: #fff; }
	}
	.body {
		background: #fff;
		border: 1px solid #ccc;
		padding: 20px 30px;
		margin-bottom: 20px;
	}	
}
span.format {
    font-size: .8em;
    display: block;
    color: #777;
}
button.btn.btn-primary {
	font-family: 'Open Sans','Trebuchet MS',Verdana,Arial,Helvetica,sans-serif;
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	padding: 10px 0;
	color: #fff;
	background-color: #4285F4;
	border-color: #4285F4;
	background-image: linear-gradient(lighten(#4285F4, 7%), #4285F4);
	box-shadow: none;
	text-shadow: none; 
	height: 43px;
	&:hover {
		color: #fff;
		background-color: darken(#4285F4, 3%);
		background-image: none;
		border: 1px solid #0062cc;
	}	
	&:active {
		line-height: 1.35;
	}
}
.section-card {
	table.backed {
		background: #fff;
		th { 
			background: #6c757d;
			color: #fff;
		}
		tr td:first-child {
			width: 50%;
		}
		td {
			border-top: 1px dotted #ccc;
			border-bottom: 1px dotted #ccc;
			word-break: break-word;
		}
	}
}
#contact_wrap {
	margin: 20px 0 30px 0;
	.card {
		padding: 15px;
    	margin: 10px auto 10px auto;
		background: #f5f7fa;
		text-align: left;
		border: 1px solid rgba(0,0,0,.125);
    	border-radius: 3px;
		cursor: pointer;
		&:hover {
			background: #fff;
		}
	}	
	.contact_icon {
		font-size: 1.1em;
		font-weight: 400;
		color: #777;
		border: 0 !important;
		&:hover { text-decoration: none; }
		i {
			display: inline-block;
			margin: 0 10px 0 0;
			height: 60px;
			width: 60px;
			font-size: 1.3em;
			color: #ccc;
			line-height: 55px;
			border-radius: 50%;
			border: 2px solid #ccc;
			vertical-align: middle;
			text-align: center;
		}		
	}
	#help {
		padding: 5px;
		margin-top: 10px;
		text-align: left;
		h4 { 
			border-bottom: 1px dotted #ccc;
		}
	}
}
.border-top-1 {
	border-top: 1px dotted #ccc;
	padding-top: 20px;
	margin-top: 30px;
}
.font-weight-bold {
	font-weight: 400;
}
/* ACCORDION */
.accord {
    padding: 10px 10px 5px 10px;
    border-top: 1px dotted #ccc;
    .accord_content{
        display: none;
        margin-top: 10px;
    }
    &.first { border-top: 0; }
    &:last-child { border-bottom: 1px dotted #ccc; }
    h2, h3, h4 {
        margin-left: 20px;
        padding-right: 20px;
        position: relative;
        cursor: pointer;
        color: #06c;
        &:before {
            color: #004dc1;
            content: "\e842";
            display: inline-block;
            font-family: "icons";
            font-size: 12px;
            font-weight: normal !important;
            height: inherit;
            margin-left: -18px;
            vertical-align: middle;
            width: 18px;
            position: relative;
            top: -2px;
        }
        &.active {
            &:before { content: "\e843"; }
        }
		&.noaccord {
			margin-left: 0;
			padding-right: 0;
			cursor: text;
			&:before, &:after { content: ""; }
		}
    }
}

/* FOOTER */
#footer_wrap {
    border-top: 1px solid #ebebeb;
    font-size: 14px;
}
#footer {
    padding-top: 20px;
    padding-bottom: 20px;
    border: 0;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
/*
$breakpoints:
xs: 0,
s: 34.375em,
m: 48em,
l: 62em,
xl: 75em
*/
@media (min-width: 23.438em) { /* Small devices (landscape phones, 375px and up) - col-sm-* */
	#header a.logo span { 
		font-size: 18px; 
		display: inline-block;
		margin-top: 3px;
	}
}
@media (min-width: 34.375em) { /* Small devices (landscape phones, 549px and up) - col-sm-* */
	#header a.logo {
		margin-right: 15px;
	}
}
@media (min-width: 37.5em) { /* Small devices (600px and up) */
}
@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) - col-md-* */
	#header #support { display: block; }    
	#content_wrap { padding: 30px 0 70px 0; }
    .accord {
        h2, h3, h4 {
            padding-right: 80px;
            &:after{
                color: #004dc1;
                content: "+ More Info";
                display: block;
                font-family: "icons";
                font-size: 14px;
                font-weight: normal !important;
                font-family: 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica;
                height: inherit;
                vertical-align: middle;
                position: absolute;
                top: 3px;
                right: 0;
            }
            &.active {
                &:before { content: "\e843"; }
                &:after { content: "- Less Info"; }
            }
        }
    }
	.section-card {
		table.backed {
			tr td:first-child { width: 25%; }
		}
	}
}
@media only screen and (min-width: 56.25em) { /* roughtly 900px/16px */
 	#header a.logo { margin-right: 15px; }   
	#header a.logo span { font-size: 22px; }
	#header a.logo img { margin-right: 15px; }
	#header { padding-top: 0; }
	.card-box {
		.card-body {
			.section { 
				.item {
					background: #fff;
					border: 1px solid #e6e9ed;
					border-radius: 3px;
					padding: 5px 10px;
					margin-bottom: 10px;
				}
				p {
					display: inline-block;
					background: transparent;
					border: 0;
					margin-bottom: 0;
				}
				br { display: block; }
			}
			.member_form { 
				form { max-width: 400px; }
			}
		}
	}
	#contact_wrap {	
		margin: 40px 0 80px 0; 
		.card {
			padding: 20px;
			margin-bottom: 20px;
		}
		.contact_icon {
			font-size: 1.3em;
		}
		#help {
			h4, p { text-align: center; }
		}
	}	
}
@media (min-width: 62em) { /* Large devices (desktops, 992px and up) - col-lg-* */
}
@media (min-width: 68.75em) { /* Large devices (desktops, 1100px and up) - col-lg-* */
}
@media (min-width: 75em) { /* Extra large devices (large desktops, 1200px and up) - col-xl-* */
}
@import 'featherlight';