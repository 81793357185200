/* ----------------------------------------------------------------------------
	Navigation Menu - Mobile first
----------------------------------------------------------------------------- */
#menutoggle {
	display: block;
	padding: 0 22px;
	width: auto;
	color: #aaa;
	/*background: #0081FF;*/
	height: 70px;
	font: 18px/46px 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
	/*text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);*/
	text-align: left;
	text-transform: uppercase;
	cursor: pointer;
	position: absolute;
	right: 0;
	bottom: 0;
	border-radius: 4px 4px 0 0;
	transition: background 0.2s linear 0s;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
#menutoggle span {
	display: none;
}
#menutoggle:before {
	display: inline-block;
	margin-left: 0;
	width: 18px;
	height: 45px;
	line-height: 48px;
	content: "\e826";
	color: inherit;
	font-family: 'icons';
	text-align: center;
	font-size: 21px;
	vertical-align: top;
}
#menutoggle.active:before {
	content: "\e844";
}
#menutoggle:hover, #menutoggle:active, #menutoggle.active {
	/*background-color: #2F98FF;*/
	border-radius: 4px 4px 0 0;
	color: #ddd;
}
#menutoggle {
	display: block;
}
#nav {
	clear: both;
	margin: 0;
	width: 100%;
	height: 0;
	overflow: visible;
	position: relative;
	z-index: 100;
}
#nav_wrap {
    background: #343a40;
}
#nav ul {
	float: none;
	display: none;
	margin: 0;
	width: auto;
	/*max-height: 0;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;*/
	background: #343a40;
	overflow: hidden;
	/*transition: all 0.1s ease-in 0s;*/
	border-radius: 0 0 2px 2px;
	top: 0;
	border: 0;
	position: relative;
}
#nav #menutoggle.active + ul {
	max-height: 1000px;
	/*-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;*/
}
#nav ul li, #nav ul ul.submenu li {
	display: block;
	margin: 0;
	padding: 0;
	height: auto;
	overflow: hidden;
	border: 0;
	/*border-top: 1px dotted rgba(255, 255, 255, 0.2);*/
}
#nav ul li:first-child {
	border-top: 0;
}
#nav ul li.onmobile {
	display: block;
}
#nav ul li a, #nav ul li span.submenu, #nav ul li ul.submenu li a, #nav ul span.title {
	float: none;
	display: block;
	margin: 0;
	padding: 9px 20px;
	height: auto;
	color: #aaa;
	line-height: 1.6em;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
	text-align: left;
	font-size: 16px;
	font-weight: normal;
	font-family: 'Open Sans', 'Helvetica Neue', Tahoma, Arial, sans-serif;
	border: 0;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#nav ul li span.submenu:after{
	float: right;
	margin-left: 10px;
	width: 15px;
	height: inherit;
	content: "\e817";
	color: inherit;
	font-family: 'icons';
	text-align: center;
	font-size: 105%;
	vertical-align: top;
}
#nav ul li span.submenu.active:after{
	content: "\e816";
}
#nav ul li.right > a.active:after{
    content: "";
}
#nav ul li.right > a.active:after
#nav ul ul.submenu {
	display: none;
	margin: 0;
	padding: 0;
	width: 100%;
	max-height: 360px;
	background: none;
	opacity: 1;
	position: relative;
	top: 0;
	overflow-y: auto;
}
#nav ul li ul.submenu li a, #nav ul span.title {
	padding-left: 40px;
	background: none;
	border: 0;
}
#nav ul li ul.submenu li a:before, #nav ul span.title:before {
	display: inline-block;
	margin-right: 5px;
	margin-left: -15px;
	width: 10px;
	height: inherit;
	content: "\00BB";
	color: inherit;
	font-family: 'icons';
	text-align: center;
	font-size: inherit;
	vertical-align: top;
}
#nav li a:hover, #nav li a.active, #nav li:hover span.submenu, #nav ul.submenu li a:hover, #nav ul.submenu li a.active {
    color: #ddd;
	text-decoration: none;
}
#nav li.indent { background: #004dc1; }
#nav li.indent a { padding-left: 45px !important; }
#nav li.indent:hover, #nav li.indent a:hover {
	background: rgb(102, 153, 204) !important;
}
@media (min-width: 23.438em) { /* Small devices (landscape phones, 375px and up) - col-sm-* */
	#menutoggle {
		height: 57px;
	}
}
@media (min-width: 36em) { /* Small devices (landscape phones, 576px and up) */
/*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
	#menutoggle {
		padding: 0 30px 0 15px;
	}
	#menutoggle span {
		display: inline-block;
	}
	#menutoggle:before {
		margin-right: 10px;
	}
}
@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) - col-md-* */
	#menutoggle {
		display: none;
	}	
}
@media (min-width: 75em) { /* Extra large devices (large desktops, 1200px and up) - col-xl-* */

    #nav_wrap{
        background: #0072FF;
        background: -moz-linear-gradient(top, #009BFF 0%, #005CFF 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#009BFF), color-stop(100%,#005CFF));
        background: -webkit-linear-gradient(top, #009BFF 0%, #005CFF 100%);
        background: -o-linear-gradient(top, #009BFF 0%, #005CFF 100%);
        background: -ms-linear-gradient(top, #009BFF 0%, #005CFF 100%);
        background: linear-gradient(to bottom, #009BFF 0%, #005CFF 100%);
    }
	#nav {
		/*height: 45px;*/
		position: relative;
		z-index: 99;
		border: 0;
		border-radius: 0;
	}
	#nav ul {
		display: block !important;
		float: left;
		width: 100%;
		height: auto;
		/*max-height: 1000px;
		-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		filter: alpha(opacity=100);
		opacity: 1;*/
		margin: 4px 0;
		border-radius: 0;
        background: transparent;
		position: relative;
		z-index: 10;
		top: 0;
		overflow: visible;
	}
	#nav ul li {
		float: left;
		margin: 0 0;
		height: 37px;
		width: auto;
		min-width: auto;
		position: relative;
		overflow: visible;
        border-left: 1px solid #00B8FF;
        border-right: 1px solid #0047FF;
        border-top: 0;
        &.callout a{
            background: #3399ff;
        }
	}
	#nav ul li:first-child {
		margin-left: 4px;
		border-left: 0;
	}
	#nav ul li.last {
		border-right: 0;
	}
	#nav ul li.right {
		float: right;
		margin-right: 4px;
        border: 0;
	}
	#nav ul li.onmobile {
		display: none;
	}
	#nav ul li a, #nav ul li span.submenu {
		float: left;
		margin: 0 4px;
		padding: 0 10px;
		height: 37px;
		border: 0;
		color: #fff;
		font-weight: normal;
		line-height: 38px;
		text-align: center;
		text-transform: uppercase;
		border-radius: 3px;
		transition: all 0.2s linear 0s;
		cursor: pointer;
	}
	#nav ul li span.submenu:after, #nav ul li.right > a:after {
		display: none;
	}
	#nav li a:hover, #nav li:hover span.submenu, #nav li:hover > a, #nav ul.submenu li a:hover, #nav ul li span.submenu.active, #nav ul li.callout span.submenu {
		background: #0081ff;
	}
	#nav ul li:hover a.submenu, #nav ul li:hover span.submenu, #nav ul li span.submenu.active.hover {
		border-radius: 3px 3px 0 0;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
    #nav li a.active{
        background: transparent;
    }
    #nav li a:hover{
        background: #3399ff;
    }
	#nav ul li:hover {
		z-index: 11;
	}
	#nav ul li ul.submenu {
		float: left;
		margin: 0;
		padding: 0;
		width: 250px;
		max-height: 0;
		/*max-height: 360px;
		overflow-y: auto;*/
		position: absolute;
		/*left: -999em;  Hides the drop down*/
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		filter: alpha(opacity=0);
		opacity: 0;
		background: #0081FF;
		left: 4px;
		top: 95%;
		z-index: 10;
		border-radius: 3px 3px 3px 3px;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
		transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
		overflow: hidden;
		overflow-y: auto;
	}
	#nav ul li.right ul.submenu {
		left: auto;
		right: 4px;
	}
	#nav ul li:hover ul.submenu, #nav ul li ul.submenu.active {
		padding: 3px 0;
		max-height: 360px;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		filter: alpha(opacity=100);
		opacity: 1;
	}
	#nav ul li ul.submenu li {
		float: none;
		display: block;
		margin: 0;
		padding: 0;
		border: 0;
		height: auto;
	}
	#nav ul li ul.submenu li a {
		float: none;
		display: block;
		margin: 0;
		padding: 7px 15px 7px 28px;
		height: auto;
		text-transform: none;
		text-align: left;
		line-height: 1.5em;
		border-radius: 0;
		border: 0;
		border-top: 1px dotted #37474f;
	}
	#nav ul li ul.submenu li:first-child a {
		border: 0;
	}
	#nav ul li ul.submenu li a:hover, #nav ul li ul.submenu li a.active {
		background: #004dc1;
	}
	#nav ul span.title {
		padding-left: 27px;
	}
	#nav li.indent a { padding-left: 38px !important; }
}
